/**
 * import
 *
 * @version 0.1 (2021.05.13, 05.17 : usayama)
 * @version 0.2 (2021.06.14 : usayama) // MapContainer コメントアウト, AboutBlockContainer に変更
 * @version 0.3 (2021.06.24 : usayama) // ZerocarbonBannersContainer 追加
 * @version 0.4 (2021.06.28 : usayama) // Seo 追加
 * @version 0.5 (2021.06.29 : usayama) // BrandBannersContainer に変更
 * @version 0.6 (2021.07.05 : usayama) // TopicsContainer 追加
 * @version 0.7 (2021.07.05 : usayama) // ThemaContainer, EventContainer, ChallengersContainer 追加
 * @version 0.8 (2021.07.16 : usayama) // MainLabelContainer 追加
 * @version 0.9 (2021.07.21 : usayama) // FashionContainer 追加
 * @version 0.10 (2021.09.29 : usayama) // MainImageContainer, NavlinkContainer 追加
 * @version 0.11 (2023.05.07 : usayama) // DsharingContainer 追加
 */
import React from 'react'
import { useTranslation } from 'react-i18next'
// conf, action
// components
import Seo from '@components/Tools/Seo'
// containers
import LayoutContainer from '@containers/LayoutContainer'
import MypointContainer from '@containers/MypointContainer'
import MainImageContainer from '@containers/MainImageContainer'
import NavlinkContainer from '@containers/NavlinkContainer'
import MainLabelContainer from '@containers/MainLabelContainer'
import BrandBannersContainer from '@containers/BrandBannersContainer'
import ThemaContainer from '@containers/ThemaContainer'
import EventContainer from '@containers/EventContainer'
import ChallengersContainer from '@containers/ChallengersContainer'
import DsharingContainer from '@containers/DsharingContainer'
import TopicsContainer from '@containers/TopicsContainer'
import ZerocarbonBannersContainer from '@containers/ZerocarbonBannersContainer'
import FashionContainer from '@containers/FashionContainer'
import ItemListContainer from '@containers/ItemListContainer'
import ShopListContainer from '@containers/ShopListContainer'
// import MapContainer from '@containers/MapContainer'
import InformationListContainer from '@containers/InformationListContainer'
// import AboutBlockContainer from '@containers/AboutBlockContainer'
// helper
// style, img, elm
// const

/**
 * const
 *
 * @version 0.1 (2021.05.13, 05.17 : usayama)
 * @version 0.2 (2021.06.11 : usayama) // 実装
 * @version 0.3 (2021.06.14 : usayama) // MapContainer コメントアウト, AboutBlockContainer に変更
 * @version 0.4 (2021.06.22 : usayama) // MainImageContainer コメントアウト
 * @version 0.5 (2021.06.24 : usayama) // ZerocarbonBannersContainer 追加
 * @version 0.5 (2021.06.28 : usayama) // Seo 追加
 */
const Top = () => {

  /** 
   * const : 
   * 
   * @version 0.1 (2021.06.28 : usayama_dx)
   */
  const { t } = useTranslation()

  /** 
   * return : 
   * 
   * @version 0.1 (2021.05.13, 05.17 : usayama)
   * @version 0.2 (2021.06.28 : usayama) // Seo 追加
   * @version 0.5 (2021.06.29 : usayama) // BrandBannersContainer に変更
   * @version 0.4 (2021.07.05 : usayama) // TopicsContainer 追加
   * @version 0.5 (2021.07.05 : usayama) // ThemaContainer, EventContainer, ChallengersContainer 追加、並び替え
   * @version 0.6 (2021.07.16 : usayama) // MainLabelContainer 追加
   * @version 0.7 (2021.07.21 : usayama) // FashionContainer 追加
   * @version 0.8 (2021.08.18 : usayama) // レイアウト調整
   * @version 0.9 (2021.08.27 : usayama) // レイアウト調整
   * @version 0.10 (2021.09.29 : usayama) // MainImageContainer, NavlinkContainer 追加, MainLabelContainer コメントアウト
   */
	return (
    <LayoutContainer>
      <Seo title={t('title.home')} />
      <MypointContainer />
      <MainImageContainer />
      <NavlinkContainer />
      {/*<MainLabelContainer />*/}
      <div className="wrap row">
        <div className="col-12 col-md-6">
          <EventContainer />
        </div>
        <div className="col-12 col-md-6">
          <TopicsContainer />
        </div>
        <div className="col-12 col-md-6">
          <ChallengersContainer />
        </div>
        <div className="col-12 col-md-6">
          <DsharingContainer />
        </div>
      </div>
      <BrandBannersContainer />
      <FashionContainer />
      <ItemListContainer />
      <ThemaContainer />
      <ShopListContainer />
      <ZerocarbonBannersContainer />
      {/*<MapContainer />*/}
      <InformationListContainer />
      {/*<AboutBlockContainer />*/}
    </LayoutContainer>
  )
}

/**
 * export
 *
 * @version 0.1 (2021.05.13, 05.17 : usayama)
 */
export default Top