/** 
 * import : 
 * 
 * @version 0.1 (2021.07.21 : usayama)
*/
import React from 'react'
import { ScaleLoader } from 'react-spinners'
import axios from 'axios'
import { IoIosArrowForward } from "react-icons/io"
// conf, action
import { app } from '@config/app.config'
import { AJAX_ROUTE } from '@config/routers'
// components
import LabelHtml from '@components/Typo/LabelHtml'
// containers
// helper
import { isEmpty, getBearer } from '@lib/helpers'
// const
const moment = require('moment')

/** 
 * const : 
 * 
 * @version 0.1 (2021.07.21 : usayama)
*/
export default function FashionContainer(props) {

  /** 
   * useState : 
   * 
   * @version 0.1 (2021.07.21 : usayama)
   */
  const [ initPage, setInitPage ] = React.useState(false)
  const [ items, setItems ] = React.useState([])
  const [ category, setCategory ] = React.useState([])
  const [ isLoad, setIsLoad ] = React.useState(false)

  /** 
   * componentDidMount : https://reactjs.org/docs/hooks-effect.html
   * 
   * @version 0.1 (2021.07.21 : usayama)
   * @version 0.2 (2021.07.26 : usayama) // per_page 追加
  */
  React.useEffect(() => {
    if(!initPage) {
      // init
      const category_id = 23;
      const per_page = 100;
      setIsLoad(true)
      // action
      axios.get(AJAX_ROUTE.getCategoryPosts + '/' + category_id + '/' + per_page, getBearer())
          .then((res) => {
            setIsLoad(false)
            setItems(JSON.parse(res.data.items))
            setCategory(JSON.parse(res.data.category))
          })
          .catch(error => error.response && error.response.data && setIsLoad(false))
      setInitPage(true)
    }
  }, [initPage])

  /** 
   * return : 
   * 
   * @version 0.1 (2021.07.21 : usayama)
   */
  return (
  <div className="brand-banners wrap">
    {
      !isEmpty(category) && 
      <div className="title title-h-line">
        <h3>{category[0].name}</h3>
        <div className="h-line">
          <span></span>
          <span></span>
        </div>
      </div>
    }
    <div className="container">
      <div className="row">
      { isLoad && <div className="text-center"><ScaleLoader color={app.loading_color} /></div> }
      { 
        !isEmpty(items) && items.map((item, i) => {
          const imgUrl = (item.acf.keyvisual !== "") ? item.acf.keyvisual : app.no_keyvisual_img_path
          return <div key={'item' + i} className="col-6 col-md-4 mb-4">
            <a href={item.link} target="_blank" rel="noreferrer">
              <span className="d-block overflow-hidden mb-2" style={{'max-height': 210}}>
                <img src={imgUrl} className="w-100" alt={item.title.rendered} />
              </span>
              <h4>{item.title.rendered}</h4>
            </a>
          </div>
        })
      }
      { 
        /*
        !isEmpty(thema) &&
          <div className="text-end">
            <a href="#" target="_blank" className="d-inline-flex align-items-center">
              <LabelHtml label="text.to_entries_list" /><IoIosArrowForward size={20} />
            </a>
          </div>
        */
      }
      </div>
    </div>
  </div>
  )
}
