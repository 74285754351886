/** 
 * import : 
 * 
 * @version 0.1 (2021.06.12, 06.14 : usayama)
*/
import React from 'react'
import { Link } from 'gatsby'
// conf, action
import { APP_ROUTE } from '@config/routers'
// components
// containers
// helper
// style, img, elm
// const
const moment = require('moment')

/** 
 * const : 
 * 
 * @version 0.1 (2021.06.12 : usayama)
*/
export default function InformationContainer(props) {

  /**
   * const
   *
   * @version 0.1 (2021.06.12 : usayama)
   */
  const { information } = props

  /** 
   * useState : 
   * 
   * @version 0.1 (2021.06.14 : usayama)
   */
  const [ initPage, setInitPage ] = React.useState(false)

  /** 
   * componentDidMount : https://reactjs.org/docs/hooks-effect.html
   * 
   * @version 0.1 (2021.06.12 : usayama)
  */
  React.useEffect(() => {
    if(!initPage) {
      setInitPage(true)
    }
  }, [initPage])

  /** 
   * return : 
   * 
   * @version 0.1 (2021.06.12, 06.14 : usayama)
   */
  return (
    <dl>
      <dt>{moment(information.publish_date).format('YYYY-MM-DD')}</dt>
      <dd><Link to={APP_ROUTE.information + information.url}>{information.title}</Link></dd>
    </dl>
  )
}
