/** 
 * import : 
 * 
 * @version 0.1 (2021.05.13 : usayama)
 * @version 0.2 (2021.06.14 : usayama) // Link 追加
*/
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
// conf, action
import informationActions from '@redux/information/actions'
// components
import Label from '@components/Typo/Label'
// containers
import InformationContainer from '@containers/InformationContainer'
// helper
// style, img, elm
const iconImg = require("@assets/images/title-icon-informations.png")
// const
const { initGetInformations, getInformations } = informationActions

/** 
 * const : 
 * 
 * @version 0.1 (2021.05.13 : usayama)
*/
export default function InformationListContainer(props) {

  /**
   * const
   *
   * @version 0.2 (2021.06.14 : usayama)
   */
  const dispatch = useDispatch()
  const { informations } = useSelector(state => state.Information)

  /** 
   * state : 
   * 
   * @version 0.1 (2021.05.13 : usayama)
  */
  const [initPage, setInitPage] = React.useState(false)

  /** 
   * componentDidMount
   * https://reactjs.org/docs/hooks-effect.html
   * 
   * @version 0.1 (2021.05.13 : usayama)
   * @version 0.2 (2021.06.14 : usayama)
   */
  React.useEffect(() => {
    if(!initPage) {
      dispatch(initGetInformations())
      dispatch(getInformations())
      setInitPage(true)
    }
  }, [initPage, dispatch])

  /** 
   * return : 
   * 
   * @version 0.1 (2021.05.13 : usayama)
   * @version 0.2 (2021.06.14 : usayama)
   * @version 0.3 (2021.07.09 : usayama) // icon コメントアウト
   */
  return (
  <div className="news informations wrap">
    <div className="title title-h-line">
      <h3><Label label="title.informations" /></h3>
      <div className="h-line">
        <span></span>
        {/*<img src={iconImg.default} alt="" />*/}
        <span></span>
      </div>
    </div>
    <div className="container">
    { informations && informations.map((information, i) => <InformationContainer information={information} key={'information' + i} /> )}
    </div>
  </div>
  )
}
