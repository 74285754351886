/** 
 * import : 
 * 
 * @version 0.1 (2021.07.09 : usayama)
 * @version 0.2 (2021.07.16 : usayama) // IoIosArrowForward 追加
*/
import React from 'react'
import { ScaleLoader } from 'react-spinners'
import axios from 'axios'
import { IoIosArrowForward } from "react-icons/io"
// conf, action
import { app } from '@config/app.config'
import { AJAX_ROUTE } from '@config/routers'
// components
import LabelHtml from '@components/Typo/LabelHtml'
// containers
// helper
import { isEmpty, getBearer } from '@lib/helpers'
// const
const moment = require('moment')

/** 
 * const : 
 * 
 * @version 0.1 (2021.07.09 : usayama)
*/
export default function EventContainer(props) {

  /** 
   * useState : 
   * 
   * @version 0.1 (2021.07.09 : usayama)
   * @version 0.2 (2021.07.21 : usayama) // category追加
   */
  const [ initPage, setInitPage ] = React.useState(false)
  const [ event, setEvent ] = React.useState([])
  const [ category, setCategory ] = React.useState([])
  const [ isLoad, setIsLoad ] = React.useState(false)

  /** 
   * componentDidMount : https://reactjs.org/docs/hooks-effect.html
   * 
   * @version 0.1 (2021.07.09 : usayama)
   * @version 0.2 (2021.07.21 : usayama) // category追加
  */
  React.useEffect(() => {
    if(!initPage) {
      // init
      const category_id = 21;
      setIsLoad(true)
      // action
      axios.get(AJAX_ROUTE.getCategoryPosts + '/' + category_id, getBearer())
          .then((res) => {
            setIsLoad(false)
            setEvent(JSON.parse(res.data.items))
            setCategory(JSON.parse(res.data.category))
          })
          .catch(error => error.response && error.response.data && setIsLoad(false))
      setInitPage(true)
    }
  }, [initPage])

  /** 
   * return : 
   * 
   * @version 0.1 (2021.07.09 : usayama)
   * @version 0.2 (2021.07.12 : usayama) // style 変更
   * @version 0.3 (2021.07.16 : usayama) // 他の記事を読むのリンク追加
   * @version 0.4 (2021.07.21 : usayama) // title 箇所変更
   * @version 0.5 (2021.08.27 : usayama) // 他の記事を読むのリンクコメントアウト解除
   * @version 0.6 (2023.08.16 : usayama) // カテゴリリンク処理変更
   */
  return (
  <div className="news informations wrap">
    {
      !isEmpty(category) && 
      <div className="title title-h-line">
        <h3>{category[0].name}</h3>
        <div className="h-line">
          <span></span>
          <span></span>
        </div>
      </div>
    }
    <div className="container">
      { isLoad && <div className="text-center"><ScaleLoader color={app.loading_color} /></div> }
      { 
        !isEmpty(event) && event.map((item, i) => {
          const imgUrl = (item.acf.keyvisual !== "") ? item.acf.keyvisual : app.no_keyvisual_img_path
          return <div className="mb-3" key={"event-" + i}>
                    <a href={item.link} className="block" target="_blank">
                      <span className="me-3 float-start overflow-hidden" style={{'width':120, 'height':90}}><img src={imgUrl} class="w-100" alt="" /></span>
                      <span>{item.title.rendered}</span>
                      <span class="clearfix"></span>
                    </a>
                  </div>
        })
      }
      { 
        !isEmpty(event) && !isEmpty(category) && 
          <div className="text-end">
            <a href={category[0].link} target="_blank" className="d-inline-flex align-items-center">
              <LabelHtml label="text.to_entries_list" /><IoIosArrowForward size={20} />
            </a>
          </div>
      }
    </div>
  </div>
  )
}
